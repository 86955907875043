import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const BlogArticle = () => {
    const navigate = useNavigate();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ articleId, setArticleId ] = useState();
    const [ article, setArticle ] = useState();
    const { articles } = useOutletContext();

    const handleGetArticleData = () => {
      const searchParamsArticleId = searchParams.get("articleId");
      if (searchParamsArticleId != null) { 
        setArticleId(searchParamsArticleId);
        console.log(searchParamsArticleId)
        
        const filteredArticles = articles.filter(article => article.id == searchParamsArticleId)
        if (filteredArticles.length > 0) {
            console.log(filteredArticles[0]);
            setArticle(filteredArticles[0]);
        }
      }
    }
    useEffect(() => {handleGetArticleData();}, [searchParams.get("articleId")])
    useEffect(() => {handleGetArticleData();},[])

    if (typeof article === "undefined") return <div>Loading...</div>
    console.log(article.attributes.content)
    
    const createdDateParsed = new Date(Date.parse(article.attributes.updatedAt));
    const createdDateString = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
  
    return (
    <div className='md:w-[1000px] mx-auto mb-32 lg:px-16 px-4'>
        <p className='text-sm text-gray-400 hover:underline hover:underline-offset-2 cursor-pointer' onClick={() => navigate("/blog")} >← Back</p>
        
        <img className='mt-2 mb-8 rounded-t-lg bg-gray-300 max-h-[700px] w-full object-cover object-top group-hover:brightness-125 transitions-all duration-1000' src={`${process.env.REACT_APP_HCMS_URL}${article.attributes.cover.data.attributes.url}`} />
        <p className='text-sm text-gray-400'>{createdDateString}</p>
        <h1 className='text-4xl font-bold mb-2'>{article.attributes.title}</h1>
        <BlocksRenderer content={article.attributes.content} 
        blocks={{
            paragraph: ({ children }) => <p className="text-gray-900">{children}</p>,
            heading: ({ children, level }) => {
                switch (level) {
                  case 1:
                    return <h1 className="text-4xl font-bold mt-8 mb-2">{children}</h1>
                  case 2:
                    return <h2 className="text-2xl font-bold mt-8 mb-2">{children}</h2>
                  case 3:
                    return <h3 className="text-xl font-semibold mt-8 mb-2">{children}</h3>
                  case 4:
                    return <h4 className="text-md font-semibold mt-8 mb-2">{children}</h4>
                  default:
                    return <h1 className="text-4xl font-bold mt-8 mb-2">{children}</h1>
                }
              },
            link: ({ children, url }) => <Link to={url}>{children}</Link>,
            list: ({ children, format }) => <ul className='list-disc ml-8'>{children}</ul>,
            image: ({ children, image }) => <img className='mt-8 rounded-lg' src={image.url} />,
        }}
        />

        {
          article.attributes.author != null && article.attributes.authorImage.data.attributes != null ? (
            <div className='mt-16 flex items-end border-t pt-2 text-gray-700 space-x-4'>
              <img className='rounded-full h-24 w-24 object-cover' src={`${process.env.REACT_APP_HCMS_URL}${article.attributes.authorImage.data.attributes.url}`} />
              <div>
                <p className='text-sm'>Author</p>
                <p className='text-xl font-semibold'>{article.attributes.author}</p>
              </div>
            </div>
          ) : <></>
        }

    </div>
  )
}

export default BlogArticle