import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const BlogWidget = ({wrapperStyle, titleStyle, dateStyle}) => {
    const [ articles, setArticles ] = useState();
    const [ retries, setRetries ] = useState(0);
    const navigate = useNavigate();

    const handleFetchBlogArticles = async () => {
        const res = await fetch(`${process.env.REACT_APP_HCMS_URL}/api/articles?sort=createdAt:desc&populate=*&fields=*`, { 
            method: 'GET', 
            headers: new Headers({
                'Authorization': 'Bearer '+ process.env.REACT_APP_HCMS_KEY, 
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
        });

        if (res.ok) {
            try {
                const json = await res.json();
                setArticles(json.data);
            } catch(error) {
                console.log(error);
            }
        } else {
            setRetries(prev => prev+1)
        }
    }

    useEffect(() => {
        if (retries == 0) {
            handleFetchBlogArticles()
        } else if (typeof articles === "undefined") {
            const timer = setTimeout(handleFetchBlogArticles, 1000)
            return () => clearTimeout(timer)
        }
    }, [retries])

    if (typeof articles === "undefined") return <div className='p-4 text-white'>Loading...</div>;
    return (
    <div className={``+wrapperStyle}>
        {
            articles.slice(0,3).map(article => {
                const createdDateParsed = new Date(Date.parse(article.attributes.updatedAt));
                const createdDateString = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
                return (
                    <div key={article.id} className={`cursor-pointer group rounded-r hover:bg-white px-4`} onClick={() => navigate("/blog/article?articleId=" + String(article.id))}>
                        <p className={``+dateStyle}>{createdDateString}</p>
                        <h3 className={``+titleStyle}>{article.attributes.title}</h3>
                    </div>
                )
            })
        }
    </div>
  )
}

export default BlogWidget