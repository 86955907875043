import React, {useEffect, useState} from 'react'

// const TEAM = [
//     {
//       name: "Dr. Benedict Bender",
//       role: "Co-Founder / Platform Specialist",
//       img: "/img/bb.png"
//     },
//     {
//       name: "Tim Körppen",
//       role: "Co-Founder / Software Engineer",
//       img: "/img/tk.png"
//     },
//     {
//       name: "Dr. Marcel Panzer",
//       role: "Co-Founder / Deep Learning Engineer",
//       img: "/img/mp.png"
//     },
//     {
//       name: "Marta Osowiecka",
//       role: "UI/UX-Expert",
//       img: "/img/mo.png"
//     },
//     {
//       name: "Matan Rosenzweig Nachum",
//       role: "Software Engineer",
//       img: "/img/mrn.png"
//     },
//     {
//       name: "Or Saadon",
//       role: "Software Engineer",
//       img: "/img/os.png"
//     },
//     {
//       name: "Noa Agami",
//       role: "Business Development",
//       img: "/img/na.png"
//     },
// ]

const AboutUs = () => {
  const [ teammembers, setTeammembers ] = useState();
  const [ retries, setRetries ] = useState(0);

  const handleFetchTeam = async () => {
      const res = await fetch(`${process.env.REACT_APP_HCMS_URL}/api/teammembers?sort=Order:asc&populate=*&fields=*`, { 
          method: 'GET', 
          headers: new Headers({
              'Authorization': 'Bearer '+ process.env.REACT_APP_HCMS_KEY, 
              'Content-Type': 'application/x-www-form-urlencoded'
          }),
      });

      console.log(res)
      if (res.ok) {
          try {
              const json = await res.json();
              console.log(json.data)
              setTeammembers(json.data);
          } catch(error) {
              console.log(error);
          }
      } else {
          setRetries(prev => prev+1)
      }
  }

  useEffect(() => {handleFetchTeam()}, [])

  return (
    <div className="mt-16 pb-16 mx-auto max-md:px-16 lg:max-w-[1000px]">

        <div className='w-full flex flex-col items-center'>
            <h1 className='text-4xl text-center font-bold'>The team behind SWAP</h1>
            <p className='text-md text-center text-gray-400 w-2/3'>Our team consists of experts from different backgrounds. Get to know our team and the people behind SWAP.</p>
        </div>

        <div className="grid md:grid-cols-3 grid-cols-1 max-sm:space-y-8 gap-8 my-6">
          {
            typeof teammembers === "undefined" ? (<>Loading...</>) : (
                <>
                    {
                        teammembers.map(person => {
                            if (person.attributes.Order != -1) {
                                return (
                                    <div className="flex-col group">
                
                                        <div className="relative mx-auto w-full md:w-72 md:h-72 flex items-end">
                                            <div className="absolute h-44 group-hover:h-72 transition-all duration-500 w-full bg-[#f7ce46] rounded-lg"></div>
                                            <img className={`object-cover rotate-0 w-full grayscale transition-all duration-500 brightness-125 group-hover:brightness-150 rounded-b-lg`} src={process.env.REACT_APP_HCMS_URL+person.attributes.Picture.data.attributes.url} alt={person.attributes.Picture.data.attributes.url} />
                                        </div>
                
                                        <p className="mt-2 text-center text-lg font-semibold text-gray-500 transition-all duration-500 group-hover:text-[#f7ce46]">{person.attributes.Name}</p>
                                        <p className="text-center italic text-gray-500">{person.attributes.Position}</p>
                
                                    </div>
                
                                )
                            }
                        })
                    }
                <div className="flex-col group">

                    <div className="relative mx-auto w-full md:w-72 md:h-72 flex items-end">
                        <div className="absolute h-44 group-hover:h-72 transition-all duration-500 w-full bg-[#f7ce46] rounded-lg"></div>
                        <img className="mt-8 object-cover w-full grayscale transition-all duration-500 brightness-125 group-hover:brightness-150 rounded-b-lg mt-16" src={process.env.REACT_APP_HCMS_URL+teammembers.filter(i => i.attributes.Order == -1)[0].attributes.Picture.data.attributes.url} alt="nn" />
                    </div>

                    <p className="mt-2 text-center text-lg font-semibold text-gray-500 transition-all duration-500 group-hover:text-[#f7ce46]">You (?)</p>
                    <div className="flex justify-center">
                        <a href="#open-positions" className="underline underline-offset-2 hover:underline-offset-4 text-center italic text-gray-500">Join our team</a>
                    </div>

                </div>
                </>
            )
          }

        </div>

        <p className="mt-16 text-gray-700">
          The idea for <span className="swap_text">swap</span> emerged from joint research and consulting activities of the founders. The team around <span className="swap_text">swap</span> includes graduates from renowned German universities such as the <span className="italic">Technical University of Munich (TUM)</span>, <span className="italic">the University of St. Gallen (HSG)</span>, the <span className="italic">Karlsruhe Institute of Technology (KIT)</span>, the <span className="italic">Humboldt University of Berlin (HU)</span> and the <span className="italic">University of Potsdam</span>. Extensive practical experience, e.g. in global strategy consultancies, renowned technology companies and successful corporations such as <span className="italic">McKinsey & Company, BCG, Deloitte, Accenture, Siemens Advanta, Berylls, SAP</span> and <span className="italic">Porsche</span> contribute to the successful execution and realization of <span className="swap_text">swap</span>.
        </p>
    </div>
  )
}

export default AboutUs