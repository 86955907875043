import React from 'react';
import { Link } from 'react-router-dom';
import { MENU_ITEMS } from './Navbar';

const Footer = () => {
  return (
    <footer className="flex justify-between bg-primary text-white items-start py-4 rounded-t-[30px] w-11/12 mx-auto bg-opacity-90">
      {/* {
        MENU_ITEMS.map(item => {

          return (
            <div>
              <p>{item.label}</p>
              {
                typeof item.children !== "undefined" ? (
                  <>
                  {
                    item.children.map(child => {
                      return (
                        <p>{child.label}</p>
                      )
                    })
                  }
                  </>
                ) : (<></>)
              }
            </div>
          )
        })
      } */}

      <div className="mx-auto w-full p-4 py-2 lg:py-3">
            <div className="md:flex md:justify-between">
              <div className="mb-6 md:mb-0">
                  <a href="/" className="flex items-center">
                      <img src="/img/swap_logos/logo_white.png" className="w-44 me-3" alt="SWAP Logo" />
                  </a>
              </div>

              <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
                  <div>
                    <Link to={"/solution"} className="text-md font-bold text-white hover:text-gray-300">Solution</Link>
                    <ul className="text-white font-medium mt-4">
                      <li><Link to={"/solution/for-seekers"} className='text-sm hover:text-gray-300'>For seekers</Link></li>
                      <li><Link to={"/solution/for-vendors"} className='text-sm hover:text-gray-300'>For vendors</Link></li>
                      <li><Link to={"/solution/for-consultants"} className='text-sm hover:text-gray-300'>For consultants</Link></li>
                    </ul>
                  </div>
                  
                  <div>
                    <Link to={"/product"} className="text-md font-bold text-white hover:text-gray-300">Product</Link>
                    <ul className="text-white font-medium mt-4">
                      <li><Link to={"/product/matching-engine"} className='text-sm hover:text-gray-300'>Matching Engine</Link></li>
                      <li><Link to={"/product/selection-portal"} className='text-sm hover:text-gray-300'>Selection Portal</Link></li>
                    </ul>
                  </div>
                  
                  <div>
                    <Link to={"/about-us"} className="text-md font-bold text-white hover:text-gray-300">About us</Link>
                    <ul className="text-white font-medium mt-4">
                      <li><Link to={"/about-us"} className='text-sm hover:text-gray-300'>SWAP team</Link></li>
                      <li><Link to={"/jobs"} className='text-sm hover:text-gray-300'>Open positions</Link></li>
                      <li><Link to={"/blog"} className='text-sm hover:text-gray-300'>News</Link></li>
                    </ul>
                  </div>
                  
                  <div>
                    <Link to={"/about-us"} className="text-md font-bold text-white hover:text-gray-300">Other</Link>
                    <ul className="text-white font-medium mt-4">
                      <li><Link to={"/contact"} className='text-sm hover:text-gray-300'>Contact</Link></li>
                      <li><Link to={"/imprint"} className='text-sm hover:text-gray-300'>Imprint</Link></li>
                      <li><Link to={"/privacy-policy"} className='text-sm hover:text-gray-300'>Privacy policy</Link></li>
                    </ul>
                  </div>
              </div>
            </div>

            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            
            <div className="flex items-center justify-between">
                <span className="text-sm text-white sm:text-center ">© 2024 <a href="/" className="hover:underline">Sectorlens GmbH</a>. All Rights Reserved.
                </span>
                <div className="flex mt-4 sm:justify-center sm:mt-0">
                    <a href="https://www.linkedin.com/company/sectorlens" target="_blank" className="text-white hover:text-gray-900 ms-5">
                      <svg class="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z" clip-rule="evenodd"/>
                        <path d="M7.2 8.809H4V19.5h3.2V8.809Z"/>
                      </svg>

                      <span className="sr-only">LinkedIn</span>
                    </a>
                </div>
            </div>
          </div>
      
    </footer>
  )
}

export default Footer