import React, { useEffect, useState } from 'react'
import JobAdRow from './Components/JobAdRow';
import { Outlet } from 'react-router-dom';

const Jobs = () => {
    const [ jobs, setJobs ] = useState();
    const [ retries, setRetries ] = useState(0);

    const handleFetchJobs = async () => {
        const res = await fetch(`${process.env.REACT_APP_HCMS_URL}/api/job-ads?sort=updatedAt:desc&populate=*&fields=*`, { 
            method: 'GET', 
            headers: new Headers({
                'Authorization': 'Bearer '+ process.env.REACT_APP_HCMS_KEY, 
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
        });
        
        if (res.ok) {
            try {
                const json = await res.json();

                setJobs(json.data);
                console.log(json.data);
            } catch(error) {
                console.log(error);
            }
        } else {
            setRetries(prev => prev+1)
        }
    }

    useEffect(() => {
        if (retries == 0) {
            handleFetchJobs()
        } else if (typeof articles === "undefined") {
            const timer = setTimeout(handleFetchJobs, 1000)
            return () => clearTimeout(timer)
        }
    }, [retries])

    const [selectedJobId, setSelectedJobId] = useState("");

    if ( typeof jobs === "undefined" ) return <div>Loading...</div>
        return (
        <Outlet context={{jobs}}/>
    )
}

export default Jobs