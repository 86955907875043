import React, {forwardRef, useState } from 'react';
import BookADemo from '../../components/BookADemo';
import SelectionPortalPhases from './Features/SelectionPortalPhases';
import { CheckMark, TiltBoxSelectionPortal } from '../01_Homepage/Features/ProductSection';
import SelectionPortalHighlights from './Features/SelectionPortalHighlights';

const SelectionPortal = () => {

    return (
    <div className="mb-2 text-gray-700 relative w-full justify-center max-md:h-fit">

        <div className='flex w-full justify-center items-start mb-32 mt-16'>
            <div className='flex w-10/12'>

            <div className='lg:w-4/12'>
                <h2 className='text-4xl font-bold mb-8'>Maximize the reliability of your enterprise system selection efficiently</h2>
                <p className='mb-4 text-gray-700'>SWAP offers a comprehensive platform on which AI-based analyses can be extended and all parties involved in software selection can work together efficiently.</p>
                    
                <ul class="space-y-4 text-left font-semibold text-gray-700">
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Useful tools for requirement surveys, presentation assessments and much more</span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Effective collaboration and communication functions </span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Methodical assistance and instructions</span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Samples, templates and automated recommendations</span>
                </li>
            </ul>


                {/* <button  onClick={() => navigate("/product/matching-engine")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                    <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                    <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                    <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                    </div>
                </button> */}

            </div>

            <div className='lg:w-8/12 max-md:hidden flex justify-center'>
                <div className='xl:w-[550px] xl:h-[550px] w-[500px] h-[500px]'>
                    <TiltBoxSelectionPortal />
                </div>
            </div>

            </div>
        </div>


        <SelectionPortalPhases />

        <SelectionPortalHighlights />

        <div className='w-full h-fit lg:px-64 mb-32 px-4'>
            <div className='border rounded-[30px] bg-opacity-90 shadow-lg p-8'>
                <BookADemo />
            </div>
        </div>


    </div>
  )
}

export default SelectionPortal