import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';


const Cookie = () => {
    const cookies = new Cookies();
    const [showBanner, setShowBanner] = useState(0);

    useEffect(() => {
        if (typeof cookies.get('cookiesAccepted') === "undefined") {
            setShowBanner(1);
        }
    }, [])

    const handleAcceptCookies = () => {
        setShowBanner(0);
        cookies.set('cookiesAccepted', true, { path: '/', maxAge: 1000000});
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div id="bottom-banner" tabindex="-1" class="fixed bottom-0 left-0 z-50 flex justify-between w-full p-4 border-t border-gray-200 bg-gray-50">
            <div class="flex items-center mx-auto pr-6">
            <p class="text-sm font-normal text-gray-500">
            We only use technically necessary cookies to ensure that our website runs smoothly. These cookies are necessary for basic navigation and security of our website and do not collect any personal information. By using our website, you consent to the use of these cookies.

            For more information, please see our <Link to={"/privacy-policy"} className='inline-block cursor-pointer underline underline-offset-2 hover:underline-offset-4 transition-all'>privacy policy</Link>.
            </p>

            </div>
            <div class="flex items-center">
            <button onClick={handleAcceptCookies} type="button" className="px-5 py-2 mr-2 text-xs font-medium text-white bg-gray-400 rounded-lg hover:bg-gray-500 focus:ring-4 focus:ring-blue-300 focus:outline-none">
                    OK, understood
                </button>
            </div>
        </div>
    )
}

export default Cookie