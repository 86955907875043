import React from 'react'
import "./TiltBox.css";

// text-align: center;
// color: #fff;
// font-size: 90px;
// font-weight: 700;
// text-transform: uppercase;

const TiltBox = ({children}) => {
  return (
    <div class="tilt-box-wrap relative w-full h-full">
		<span class="hover_area"></span>
		<span class="hover_area"></span>
		<span class="hover_area"></span>
		<span class="hover_area"></span>
		<span class="hover_area"></span>
		<span class="hover_area"></span>
		<span class="hover_area"></span>
		<span class="hover_area"></span>
		<span class="hover_area"></span>
		<div class="tilt-box w-full h-full relative flex items-center justify-around">
            {children}
		</div>
	</div>
  )
}

export default TiltBox