import React from 'react'
import { CheckMark } from '../../01_Homepage/Features/ProductSection'
import { useNavigate } from 'react-router-dom';

const ForSeekersHeader = ({inOverviewPage}) => {
    const navigate = useNavigate();
  return (
    <div className={`lg:flex items-center lg:space-x-16 px-4 px-16 py-16`}>
          
        <div className='lg:hidden w-full lg:p-8 mb-16'>
            <img src="/img/product_pictures/screens_matchingengine/matching_process.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
        </div>

        <div className='lg:w-4/12 '>
            {inOverviewPage ? <p className='text-gray-800 text-sm uppercase font-semibold'>For software seekers</p> : <></>}
            <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Find your enterprise system fast and reliably</h2>
            <p className='mb-4 text-gray-700'>Experience how SWAP can support you in your software selection project. SWAP's advanced AI connects your business with the ideal enterprise system solutions, precisely suited to meet your specific needs and goals. Additionally, SWAP provides you with comprehensive guidance and useful tools for an efficient software selection process.</p>
            
            <ul class="space-y-4 text-left font-semibold text-gray-700">
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Receive an individual shortlist in less then 6 minutes</span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Be guided efficiently and collaboratively through the entire selection process</span>
                </li>
                <li class="flex items-center space-x-3 rtl:space-x-reverse">
                    <CheckMark />
                    <span>Stay anonymous and only get in touch with vendors and partners when you want to</span>
                </li>
            </ul>

            <button  onClick={() => navigate("/solution/for-seekers")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
            </div>
            </button>


        </div>
        
        <div className='lg:w-7/12 max-md:hidden w-full lg:p-8 mb-16'>
            <img src="/img/product_pictures/screens_matchingengine/matching_process.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
        </div>
    </div>
  )
}

export default ForSeekersHeader