import React, { useState } from 'react';

const NewsletterForm = ({setOpen, setSurveyModalOpen}) => {
    const [ submitted, setSubmitted ] = useState(false);
  return (
    <div>
        {
        !submitted ? (<p id="newsletter_teaser" className="mb-6 text-gray-700">If you don't want to miss anything, sign up for the newsletter. We will inform you about updates and the launch of <span className="swap_text">swap</span>.</p>) : (<></>)
        }

        <form method="post" action="https://s7digital.de/?na=s" target="_blank" onSubmit={() => setSubmitted(true)} style={{display: submitted ? "none" : ""}}>
        <input type="hidden" name="nl[]" value="2" />
        <input type="hidden" name="nlang" value="" />
        <p></p>
        <div className="relative"><label for="tnp-1">E-mail address</label>
            <input className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dk:bg-gray-700 dk:border-gray-600 dk:placeholder-gray-400 dk:text-white dk:focus:ring-blue-500 dk:focus:border-blue-500" type="email" name="ne" id="tnp-1" placeholder="" required="" />
            <div className=""><input className="cursor-pointer text-primary absolute right-2.5 bottom-2.5 bg-[#f7ce46] font-bold hover:scale-105 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm px-4 py-2 dk:bg-blue-600 dk:hover:bg-blue-700 dk:focus:ring-blue-800" type="submit" value="Subscribe" /></div>
        </div>
        <div className="mt-2">
            <label className='mt-4 text-gray-700 dk:text-gray-300'>
            <input type="checkbox" name="ny" required="" className="mr-4" />
            I consent to <span className="swap_text">swap</span> informing me by e-mail about product related updates. My data will be used exclusively for this purpose. It will not be passed on to third parties.
            </label>
        </div>
        </form>

        {
        submitted ? (
        <div id="newsletter_response" className="text-gray-700">
            A confirmation email is on the way. Follow the instructions and check the spam folder. Thank you.

            {
                typeof setOpen !== "undefined" && typeof setSurveyModalOpen !== "undefined" ? (
                    <p className="font-semibold">
                    Do you have time for a short survey (2 min)? 
                    <a href="#" className="underline text-primary" onClick={() => {setOpen(false); setSurveyModalOpen(true);}}>Click here</a>
                    </p>
                ) : <></>
            }
        </div>) : (<></>)
        }

    </div>
  )
}

export default NewsletterForm