import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom';

const Blog = () => {
    const [ articles, setArticles ] = useState();
    const [ retries, setRetries ] = useState(0);

    const handleFetchBlogArticles = async () => {
        const res = await fetch(`${process.env.REACT_APP_HCMS_URL}/api/articles?sort=createdAt:desc&populate=*&fields=*`, { 
            method: 'GET', 
            headers: new Headers({
                'Authorization': 'Bearer '+ process.env.REACT_APP_HCMS_KEY, 
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
        });

        if (res.ok) {
            try {
                const json = await res.json();
                setArticles(json.data);
            } catch(error) {
                console.log(error);
            }
        } else {
            setRetries(prev => prev+1)
        }
    }

    useEffect(() => {
        if (retries == 0) {
            handleFetchBlogArticles()
        } else if (typeof articles === "undefined") {
            const timer = setTimeout(handleFetchBlogArticles, 1000)
            return () => clearTimeout(timer)
        }
    }, [retries])

    if ( typeof articles === "undefined" ) return <div>Loading...</div>
        return (
        <Outlet context={{articles}}/>
    )
}

export default Blog