import React from 'react';
import { parseDate } from '../../../utils/dateParser';
import { useNavigate } from 'react-router-dom';


const JobAdRow = ({job}) => {
    const navigate = useNavigate();

  return (
    <div className='w-full lg:h-24 border-b p-4 lg:flex justify-between items-end group cursor-pointer' onClick={() => navigate("job?jobId=" + String(job.id))}>

        <div>
            <p className='text-sm italic'>
                {parseDate(job.attributes.updatedAt)} - Active
            </p>
            <p className='text-3xl font-bold group-hover:text-primary truncate'>{job.attributes.jobTitle}</p>
        </div>

        <div className='flex items-end'>
            <button className="text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                </div>
            </button>
        </div>

    </div>
  )
}

export default JobAdRow