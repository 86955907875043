import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { parseDate } from '../../../utils/dateParser';

const JobAd = () => {
    const navigate = useNavigate();
    const { jobs } = useOutletContext();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ jobId, setJobId ] = useState();
    const [ job, setJob ] = useState();

    const handleGetJobData = () => {
      const searchParamsJobId = searchParams.get("jobId");
      if (searchParamsJobId != null) { 
        setJobId(searchParamsJobId);
        
        const filteredJobs = jobs.filter(job => job.id == searchParamsJobId)
        if (filteredJobs.length > 0) {
            setJob(filteredJobs[0]);
        }
      }
    }

    useEffect(() => {handleGetJobData();}, [searchParams.get("jobId")])
    useEffect(() => {handleGetJobData();},[])

    if (typeof job === "undefined") return <div>Loading...</div>

    return (
        <div className='md:w-[1000px] md:px-16 px-4 mx-auto mb-32'>
            <p className='text-sm text-gray-400 hover:underline hover:underline-offset-2 cursor-pointer' onClick={() => navigate("/jobs")} >← Back</p>
            
            <p className='text-sm text-gray-400 mt-16'>{parseDate(job.attributes.updatedAt)} - Active</p>
            <h1 className='text-4xl font-bold mb-8'>{job.attributes.jobTitle}</h1>
            <BlocksRenderer content={job.attributes.jobDescription} 
            blocks={{
                paragraph: ({ children }) => <p className="text-gray-900">{children}</p>,
                heading: ({ children, level }) => {
                    switch (level) {
                      case 1:
                        return <h1 className="text-4xl font-bold mt-8 mb-2">{children}</h1>
                      case 2:
                        return <h2 className="text-2xl font-bold mt-8 mb-2">{children}</h2>
                      case 3:
                        return <h3 className="text-xl font-semibold mt-8 mb-2">{children}</h3>
                      case 4:
                        return <h4 className="text-md font-semibold mt-8 mb-2">{children}</h4>
                      default:
                        return <h1 className="text-4xl font-bold mt-8 mb-2">{children}</h1>
                    }
                  },
                link: ({ children, url }) => <Link to={url} className='underline underline-offset-2 hover:underline-offset-4 transition-all text-primary font-semibold'>{children}</Link>,
                list: ({ children, format }) => <ul className='list-disc ml-8'>{children}</ul>,
            }}
            />
        </div>
    )
}

export default JobAd