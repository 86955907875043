import React, {forwardRef, useState } from 'react';
import ProductSection from '../01_Homepage/Features/ProductSection';
import BookADemo from '../../components/BookADemo';

const ProductOverview = () => {
    const [currentContentIndex, setCurrentContentIndex] = useState(0);

    const updateContent = (index) => {
      setCurrentContentIndex(index);
    };

    return (
    <div className="mb-2 text-gray-700 flex flex-col items-center justify-center relative w-full justify-center max-md:h-fit">

        <ProductSection />

        <div className='w-full h-fit lg:px-64 mb-32 px-4'>
          <div className='border rounded-[30px] bg-opacity-90 shadow-lg p-8'>
              <BookADemo />
          </div>
      </div>

    </div>
  )
};

export default ProductOverview;