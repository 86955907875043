import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BlogWidget from '../pages/03_Blog/Components/BlogWidget';
import JobsWidget from '../pages/05_Jobs/Components/JobsWidget';

export const MENU_ITEMS = [
    { label: "Home", path: "/" },
    { label: "Solution", path: "/solution", children: [
        { label: "Overview", path: "/solution"},
        { label: "For seekers", path: "/solution/for-seekers", content: <div className='text-white pl-4'><p className='text-xl font-bold'>Identify suitable enterprise systems with SWAP</p><p className='mt-4'>Companies looking for software such as ERP, CRM or HR systems are faced with a complicated selection process and a confusing market. SWAP enables these searchers to make an individual selection.</p></div> },
        { label: "For vendors", path: "/solution/for-vendors", content: <div className='text-white pl-4'><p className='text-xl font-bold'>Market your enterprise system with SWAP</p><p className='mt-4'>As a provider of an enterprise system, you are in competition with many others. SWAP enables you to gain visibility in the market and generate successful leads.</p></div> },
        { label: "For consultants", path: "/solution/for-consultants", content: <div className='text-white pl-4'><p className='text-xl font-bold'>Streamline your software selection projects with SWAP</p><p className='mt-4'>Software selection processes involve processing complex customer information and staying up-to-date with the software market. SWAP supports you in automating processes, improving communication and keeping an eye on the market.</p></div> },
    ] },
    { label: "Product", path: "/product", children: [
        { label: "Product overview", path: "/product" },
        { label: "SWAP Matching Engine", path: "/product/matching-engine" },
        { label: "SWAP Selection Portal", path: "/product/selection-portal" },
        // { label: "Join closed beta phase", path: "/" },
    ] },
    { label: "About us", path: "/about-us", children: [
        { label: "Team", path: "/about-us" },
        { label: "Jobs", path: "/jobs", content: <JobsWidget wrapperStyle={"space-y-2"} titleStyle={"truncate text-white group-hover:text-primary font-semibold text-lg"} dateStyle={"text-white group-hover:text-primary"} />},
        { label: "News", path: "/blog", content: <BlogWidget wrapperStyle={"space-y-2"} titleStyle={"truncate text-white group-hover:text-primary font-semibold text-lg"} dateStyle={"text-white group-hover:text-primary"} /> },
    ] },
    { label: "Contact", path: "/contact" }
]

const Navbar = ({ sectionRefs }) => {
    const navigate = useNavigate();
    const [subNavbarHover, setSubNavbarHover] = useState(false);
    const [subNavbarOpenForChildren, setSubNavbarOpenForChildren] = useState(null);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    
    const handleClickToNavigate = (url) => {
        setSubNavbarHover(false);
        setSubNavbarOpenForChildren(null);
        setMobileMenuOpen(false);
        navigate(url);
    }

    return (
        <>
            <nav className="sticky pt-5 top-0 z-50 opacity-100 text-base max-md:w-11/12 md:w-11/12 max-w-screen mx-auto" onMouseEnter={() => {setSubNavbarHover(true);}} onMouseLeave={() => {setSubNavbarHover(false); setSubNavbarOpenForChildren(null)}}>
                <div className={`relative flex flex-col w-full bg-primary border-2 border-primary backdrop-blur-md bg-opacity-90 hover:bg-opacity-90 transition-blur duration-500 max-md:px-6 md:px-12 rounded-t-[30px] rounded-b-[30px] ${subNavbarOpenForChildren ? "h-fit" : "h-[60px]"} lg:snap-start p-2 transition-all`}>

                    <div className='lg:pt-[7px] flex w-full items-center lg:justify-center justify-between'>
                        <Link to={"/"} className="lg:absolute lg:left-5 lg:p-2 flex items-center space-x-3 h-fit cursor-pointer" >
                            <img src='/img/swap_logos/logo_white.png' className="h-[40px]" alt="Logo"/>
                        </Link>
                        
                        <div className="hidden md:flex md:w-auto md:order-1">
                            <ul className="flex flex-col mt-4 font-medium md:flex-row md:mt-0 md:space-x-8 rtl:space-x-reverse">
                                {
                                    MENU_ITEMS.map(item => (
                                        <li className='relative cursor-pointer' onMouseEnter={() => {if (typeof item.children !== "undefined") {setSubNavbarOpenForChildren(item.children)} else {setSubNavbarOpenForChildren(null)}}} onMouseLeave={() => {if (!subNavbarHover) setSubNavbarOpenForChildren(null)}}>
                                            <div className="text-white hover:text-primary2" onClick={() => handleClickToNavigate(item.path)}>{item.label}</div>
                                        </li>

                                    ))
                                }
                            </ul>
                        </div>

                        <div className="flex items-center md:order-2 space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <button data-collapse-toggle="mega-menu" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-full md:hidden hover:bg-primary focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="mega-menu" aria-expanded={isMobileMenuOpen} onClick={() => setMobileMenuOpen(prev => !prev)}>
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                    <path stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                                </svg>
                            </button>
                        </div>
                    </div>


                    {/* <div className={`px-12 bg-primary bg-opacity-90 ${subNavbarOpenForChildren != null ? "h-64 p-8" : "h-0"} rounded-b-[30px] flex transition-all duration-300`}> */}
                    {
                        subNavbarOpenForChildren != null ? (
                                <SubMenu handleClickToNavigate={handleClickToNavigate} subNavbarOpenForChildren={subNavbarOpenForChildren} />
                            ) : (<></>)
                    }
                    {/* </div> */}
                </div>


            </nav>


            {isMobileMenuOpen && (
                <MobileMenu handleClickToNavigate={handleClickToNavigate} />
            )}
        </>
    );
}

const SubMenu = ({handleClickToNavigate, subNavbarOpenForChildren}) => {
    const [hoveredChild, setHoveredChild] = useState(<></>);
    const [hoveredChildParentLabel, setHoveredChildParentLabel] = useState("");
    useEffect(() => {setHoveredChild(<></>)}, [subNavbarOpenForChildren])

    return (
        <>
            <div className={`px-5 pt-6 flex`}>
                <div className='grid grid-cols-1 grid-rows-3 w-1/2 border-r pb-16'>
                    {
                        subNavbarOpenForChildren.map(child => (
                            <div className={`cursor-pointer py-3 pl-3 text-lg font-semibold hover:text-primary hover:bg-gray-100 ${hoveredChildParentLabel == child.label ? "text-primary bg-gray-100" : "text-white"} rounded-l-lg`} onClick={() => handleClickToNavigate(child.path)} onMouseEnter={(e) => {if (typeof child.content !== "undefined") {setHoveredChild(child.content); setHoveredChildParentLabel(child.label)} else {setHoveredChild(null); setHoveredChildParentLabel("")}}}>
                                {child.label}
                            </div>
                        ))
                    }
                </div>

                <div className='w-1/2 pr-5' >{hoveredChild}</div>
            </div>
        </>
    )
}

const MobileMenu = ({handleClickToNavigate}) => {
    const [subMenuOpenForItem, setSubMenuOpenForItem] = useState(null);
    const handleClickMenuItem = (item) => {
        if (typeof item.children === "undefined") return handleClickToNavigate(item.path);

        if (subMenuOpenForItem == null) return setSubMenuOpenForItem(item.label);
        if (subMenuOpenForItem != item.label) return setSubMenuOpenForItem(item.label);

        return setSubMenuOpenForItem(null);
        
    }
    return (
        <div className="bg-primary backdrop-blur-md bg-opacity-90 w-10/12 mx-auto justify-center rounded-lg mt-2 text-base p-8 md:hidden">
            <ul className="flex flex-col max-h-[70vh] overflow-y-auto space-y-1">
                {
                    MENU_ITEMS.map(item => (
                        <li onClick={() => handleClickMenuItem(item)} className={`cursor-pointer p-4 ${subMenuOpenForItem == item.label ? "text-primary bg-white" : "text-white"} hover:text-primary hover:bg-white rounded-lg`}>
                            <div to={item.path} className="">{item.label}</div>
                            { subMenuOpenForItem == item.label ? (
                                <div className='bg-white border-t pt-4 mt-4 space-y-1'>
                                    {
                                        item.children.map(child => (
                                            <div onClick={() => handleClickToNavigate(child.path)} className='p-4 hover:bg-primary hover:text-white rounded-lg'>{child.label}</div>
                                        ))
                                    }
                                </div>
                            ) : (<></>)}
                            <div></div>
                        </li>

                    ))
                }
            </ul>
        </div>
    )
}

export default Navbar;


