import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

const CalComWidget = () => {
    
    useEffect(()=>{
        (async function () {
        const cal = await getCalApi({"namespace":"30min"});
        cal("ui", {"styles":{"branding":{"brandColor":"#000000"}},"hideEventTypeDetails":false,"layout":"month_view"});
        })();
    }, [])

    return <Cal namespace="30min"
        calLink="sectorlens/30min"
        style={{width:"100%",height:"100%",overflow:"scroll"}}
        config={{layout: 'month_view'}}
    />;
};

export default CalComWidget