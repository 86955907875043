import React, { useEffect, useState } from 'react'
import { CheckMark } from '../../01_Homepage/Features/ProductSection'
import { useNavigate } from 'react-router-dom';
import ForVendorsHeader from './ForVendorsHeader';
import BookADemo from '../../../components/BookADemo';
import TiltBox from '../../../components/TiltBox';
import Gallery from '../../../components/Gallery';
import LargeImageZoom from '../../../components/LargeImageZoom';

const ForVendorsPage = () => {
  const navigate = useNavigate();
  return (
    <div className="mb-2 text-gray-700 flex flex-col items-center justify-center relative w-full justify-center max-md:h-fit max-md:px-8">
      
      <ForVendorsHeader />
      
      <>
        <div id="listing" className='mb-32'>
          <div className='w-full flex flex-col items-center mb-16'>
            <h1 className='lg:text-4xl text-2xl text-center font-bold'>Free listing and detailed matching of suitable customers</h1>
            <p className='text-md text-center text-gray-400 w-2/3'>Gain visibility in the market by listing your system and its features for free and be matched to potential customers.</p>
          </div>

          <div className='lg:flex items-start lg:space-x-16 lg:w-[1200px] py-16'>
            <div className='lg:w-1/2 lg:pl-16 hover:scale-[105%] transition-all duration-500 cursor-pointer'>
              <img src="/img/product_pictures/screens_matchingengine/matchingengine_results.png" className="w-full" />
            </div>
            <div className='lg:w-1/2 lg:pr-16'>
              <div className="mt-2 flex p-4 justify-between rounded-lg items-center">
                  <div>
                      <h6 className="text-xl font-semibold mb-2">Increase your visibility in the market</h6>
                      <p className="pb-2">By listing your system, modules and industry packages free of charge, you gain additional visibility to potential customers. You can use product pages to present your systems and link your websites and contact details.</p>
                  </div>
              </div>
              <div className="mt-2 flex p-4 justify-between rounded-lg items-center">
                  <div>
                      <h6 className="text-xl font-semibold mb-2">Highlight your industry-specific strengths</h6>
                      <p className="pb-2">SWAP incorporates individual customer requirements and system functions into the matching process. This enables you to emphasize the special features of your system and appear more attractive to your target group or niche.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>


      </>

      <>
          <div id="sales-leads">
            <div className='w-full flex flex-col items-center mb-16'>
              <h1 className='lg:text-4xl text-2xl text-center font-bold'>Higly qualified sales leads</h1>
              <p className='text-md text-center text-gray-400 w-2/3'>Receive custom-fit sales leads that have been pre-qualified by SWAP AI to create more accurate offers and maximize your conversion rate.</p>
            </div>

          </div>

          <div className='lg:w-8/12 max-md:hidden flex justify-center'>
            <div className='xl:w-[700px] xl:h-[700px] w-[500px] h-[500px]'>
              <TiltBoxSalesLead />
            </div>

          </div>
          
          <div className='lg:hidden flex justify-center'>
              <img src='/img/product_pictures/lead.png' />
          </div>

          <div className='lg:flex lg:mt-40 mt-8 w-full lg:px-32 lg:px-8 px-4 lg:space-x-16'>
            
            <div className='lg:w-1/2 '>
              <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Comprehensive background information and requirements</h2>
              <p className='mb-4 text-gray-700'>Obtain comprehensive background information about the organizational structures and requirements of a potential customer to make more precise offers and thus maximize the conversion rate.</p>

            </div>
            
            <div className='lg:w-1/2 '>
              <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Transparent success rate and competitive situation</h2>
              <p className='mb-4 text-gray-700'>Find out how well your system fits a customer and how well other systems fit before you buy the sales lead. This way you can actively pursue only the leads where your system is among the best fit.</p>
              
            </div>

          </div>
      </>

      <div className='w-full h-fit lg:px-64 my-32 px-4'>
          <div className='border rounded-[30px] bg-opacity-90 shadow-lg p-8'>
              <BookADemo />
          </div>
      </div>


    </div>
  )
}

export default ForVendorsPage


const FloatingTextLead = ({texts, startIdx, startOffsetMs, quadrant}) => {

  const [floatingText, setFloatingText] = useState({top: 50, left: 50, value: texts[startIdx]});
  const [textVisible, setTextVisible] = useState(false);
  const [textInvisible, setTextInvisible] = useState(true);
  const [started, setStarted] = useState(false);

  const setNewPositions = () => {
    const topPos = Math.min(Math.round(Math.random()*100), 70);
    const leftPos = Math.min(Math.round(Math.random()*100)-50, 70);
    const textValueIdx = Math.floor(Math.random() * texts.length);
    setFloatingText({top: topPos, left: leftPos, value: texts[textValueIdx]})
    setTextVisible(true);
    setTextInvisible(false);
  }

  const removeItem = () => {
    setTextVisible(false);
    setTextInvisible(true);
  }

  useEffect(() => {
    if (textVisible) {
      const timer = setTimeout(() => removeItem(), 5000+Math.random()*1000);
      return () => clearTimeout(timer)
    }
   }, [textVisible])
  
   useEffect(() => {
     if (textInvisible && started) {
      const timer = setTimeout(() => setNewPositions(), 1000+Math.random()*100);
      return () => clearTimeout(timer)
    }
   }, [textInvisible])
   
   useEffect(() => {
      const timer = setTimeout(() => setNewPositions(), startOffsetMs);
      setStarted(true);
      return () => clearTimeout(timer)
   }, [])

   return (
    <div className={`absolute left-0 top-0 w-full h-full p-4 flex ${quadrant[0] == 0 ? "justify-start" : "justify-end" } ${quadrant[1] == 0 ? "items-start" : "items-end" } transition-transform duration-[3000ms]`} style={{transform: `translateZ(${textInvisible ? "0" : "100"}px)`}}>
      <div className='relative w-[50%] h-[50%]'>
        <div className={`absolute p-4 cursor-pointer text-border-red-400 bg-white/40 rounded-full backdrop-blur-md drop-shadow-md ${Math.random() < .5 ? "text-primary2" : "text-primary"} transition-opacity transition-blur duration-500 text-2xl font-bold text-nowrap ${textInvisible ? "opacity-0 blur" : "opacity-80"}`} style={{top: `${floatingText.top}%`, left: `${floatingText.left}%`}}>
            {floatingText.value}
        </div>
      </div>
    </div>
   )

}

const TiltBoxSalesLead = () => {
  const texts = ["Customer produces machines", "Worldwide production sites", "Series production and individual production", "B2B webshop", "Intermediate or buffer storage", "Newsletter distribution", "Multi-currency capability", "Cloud operation", "3000 Employees", "345 Mio. € revenue p.a.", "Your rank: 3rd place out of 30 systems", "Your functional fit: 90%", "Competition: 2 other systems fit better or similarly well"]

  return (
    <TiltBox>
      <div className='absolute left-0 top-0 w-1/2 h-1/2 flex justify-end items-end p-4' style={{transform: "translateZ(45px)"}}>
        <img src="/img/product_pictures/tilt_saleslead/tilt_saleslead_1.png" alt="" className="drop-shadow-xl" />
      </div>
      <div className='absolute left-1/2 top-0 w-1/2 h-1/2 flex justify-start items-end p-4' style={{transform: "translateZ(60px)"}}>
        <img src="/img/product_pictures/tilt_saleslead/tilt_saleslead_2.png" alt="" className="drop-shadow-xl" />
      </div>
      <div className='absolute left-0 top-1/2 w-1/2 h-1/2 flex justify-end items-start p-4' style={{transform: "translateZ(50px)"}}>
        <img src="/img/product_pictures/tilt_saleslead/tilt_saleslead_3.png" alt="" className="h-[70%] drop-shadow-xl" />
      </div>
      <div className='absolute left-1/2 top-1/2 w-1/2 h-1/2 flex justify-start items-start p-4' style={{transform: "translateZ(30px)"}}>
        <img src="/img/product_pictures/tilt_saleslead/tilt_saleslead_4.png" alt="" className="drop-shadow-xl" />
      </div>

      <div className='absolute left-0 top-0 w-full h-full flex justify-center items-center p-4' style={{transform: "translateZ(0px)"}}>
        <div className='rounded-full border-8 border-primary2 w-1/2 h-1/2 m-4 drop-shadow-xl drop-shadow-primary-2 animate-pulse blur-sm '>
        </div>
      </div>

      <FloatingTextLead texts={texts} startIdx={0} startOffsetMs={0} quadrant={[0,0]} />
      <FloatingTextLead texts={texts} startIdx={0} startOffsetMs={1000} quadrant={[0,1]} />
      <FloatingTextLead texts={texts} startIdx={0} startOffsetMs={3000} quadrant={[1,0]} />
      <FloatingTextLead texts={texts} startIdx={1} startOffsetMs={10000} quadrant={[1,1]} />
      
    </TiltBox>
  )
}