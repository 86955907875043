import React from 'react';
import HeroSection from './Features/HeroSection';
import TeamSection from './Features/TeamSection';
import ContactSection from './Features/ContactSection';
import JobsSection from './Features/JobsSection';
import ProductSection from './Features/ProductSection';

const Homepage = () => {
  return (
    <div>

      <HeroSection />

      <ProductSection />

      <div className='flex max-md:flex-col items-start lg:space-x-12 my-16 lg:px-12 max-w-[500px] lg:max-w-[1200px] mx-auto px-4'>
        <TeamSection />
        <JobsSection />
      </div>

      <ContactSection />


    </div>
  );
}

export default Homepage;
