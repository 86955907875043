import React, { useState } from 'react'
import NewsletterModal from '../../../components/NewsletterModal';
import SurveyModal from '../../../components/SurveyModal';
import NewsletterForm from '../../../components/NewsletterForm';
import { useNavigate } from 'react-router-dom';

const ContactSection = () => {
  const navigate = useNavigate();

  const [ newsletterModalOpen, setNewsletterModalOpen ] = useState(false);
  const [ surveyModalOpen, setSurveyModalOpen ] = useState(false);
  
  return (
    <section className="mt-8 pb-32 mx-auto px-4 lg:max-w-[800px]">
      <div id="contact" className="text-center sm:p-8">
        <h5 className="mb-2 text-5xl font-bold text-primary dk:text-white">Let's stay in contact</h5>
        <p className="mb-5 text-base text-gray-700 sm:text-lg dk:text-gray-400">Interested in <span className="swap_text">swap</span>, a potential collaboration, or opportunities to get involved? Please feel free to contact us.</p>
        <div className="flex items-center justify-center w-full">
          <div className="lg:space-x-4 max-md:space-y-4">
          
          {/* <a href="mailto:swap@sectorlens.de" className="w-full">
            <button type="button" className=" text-base font-medium text-white items-center bg-[#f7ce46] hover:scale-110 transition-all focus:ring-4 focus:outline-none rounded-lg text-center">
              <div className="w-full p-2 text-xl font-semibold text-primary sm:p-8 dk:bg-gray-800 dk:border-gray-700">
                Contact us by e-mail
              </div>
            </button>
          </a> */}

          <button  onClick={() => setSurveyModalOpen(true)} className="w-64 px-2 text-base font-medium text-white items-center bg-[#f7ce46] hover:scale-110 transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="w-full p-2 text-xl font-semibold text-primary sm:p-8 dk:bg-gray-800 dk:border-gray-700">
              Tell us your opinion
            </div>
          </button>
          
          <button  onClick={() => navigate("contact")} className="w-64 px-2 text-base font-medium text-white items-center bg-[#f7ce46] hover:scale-110 transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="w-full p-2 text-xl font-semibold text-primary sm:p-8 dk:bg-gray-800 dk:border-gray-700">
              Contact us
            </div>
          </button>

          </div>
        </div>
      </div>

      <div className='mx-auto lg:w-2/3 w-full mt-8'>
        <h3 className='text-2xl font-semibold text-primary mb-2'>Join the newsletter</h3>
        <NewsletterForm />
      </div>


      {/* <NewsletterModal isOpen={newsletterModalOpen} setOpen={setNewsletterModalOpen} setSurveyModalOpen={setSurveyModalOpen} /> */}
      <SurveyModal isOpen={surveyModalOpen} setOpen={setSurveyModalOpen} />
    </section>
  )
}

export default ContactSection