import React from 'react'
import BaseModal from './BaseModal'
import BookADemo from './BookADemo'

const BookADemoModal = ({isOpen, setOpen}) => {
  return (
    <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <div className='lg:w-[70vw] w-[85vw] h-[70vh] overflow-y-auto'>
            <BookADemo />
        </div>
    </BaseModal>
  )
}

export default BookADemoModal