import React, { useState } from 'react'
import BaseModal from './BaseModal'

const SurveyModal = ({isOpen, setOpen}) => {
    const [consentGiven, setConsentGiven] = useState(false);

    const handleGiveConsent = () => {
        
        setConsentGiven(true);
        // document.getElementById('google_forms_embedd').src = 
    }
    return (
    <BaseModal isOpen={isOpen} setOpen={setOpen}>
        <div className='lg:w-[60vw] w-[85vw] transition-all'>
            {
                !consentGiven ? (
                    <div onClick={() => handleGiveConsent()} className="overflow-y-auto cursor-pointer h-[400px] md:h-[500px] xl:h-[700px] flex items-center justify-center bg-gray-300 rounded-lg">
                        <div className="flex flex-col w-full">
                            
                            <svg className="mx-auto w-10 h-10 max-md:w-5 max-md:h-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>

                            <p className="max-md:text-sm text-center text-gray-700 p-6">
                                Before we display the form, we need your consent. Loading the form will transmit information to Google, including your IP address, and cookies will be stored on your device. Please click here to give your consent and view the form. More details can be found in our privacy policy.
                            </p>
                        </div>
                    </div>
                ) : (
                    <iframe id="google_forms_embedd" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0" src="https://docs.google.com/forms/d/e/1FAIpQLSc_uRlbRLZ5Ls0w_1S98NtcvlaBzvCwykf8wCoVbi8lG8DdCA/viewform?embedded=true">Loading…</iframe>
                )
            }
        </div>
    </BaseModal>
  )
}

export default SurveyModal