import React, { useState } from 'react'
import BaseModal from './BaseModal'
import SurveyModal from './SurveyModal';
import NewsletterForm from './NewsletterForm';

const NewsletterModal = ({isOpen, setOpen, setSurveyModalOpen}) => {

  return (
    <BaseModal isOpen={isOpen} setOpen={setOpen}>
      <div className='w-full'>
        <NewsletterForm />
      </div>
    </BaseModal>
  )
}

export default NewsletterModal