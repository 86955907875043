import React from 'react';
import MatchingEngineFlow from '../../02_Product/Features/MatchingEngineFlow';
import SelectionPortalPhases from '../../02_Product/Features/SelectionPortalPhases';
import SelectionPortalHighlights from '../../02_Product/Features/SelectionPortalHighlights';
import BookADemo from '../../../components/BookADemo';

const ForSeekersPage = () => {
  return (
    <div className="mb-2 text-gray-700 flex flex-col items-center justify-center relative w-full justify-center max-md:h-fit">

        <div className='w-full flex flex-col items-center px-4'>
            <h1 className='text-4xl text-center font-bold'>Find your enterprise system in just 6 minutes</h1>
            <p className='text-md text-center text-gray-400 w-2/3'>Experience how our advanced AI connects your business with the ideal enterprise system solutions, precisely suited to meet your specific needs and goals. By leveraging cutting-edge technology, we ensure a seamless and efficient process to identify the best matches for your unique requirements.</p>
        </div>
        
        {/* ACHTUNG: DIESE KOMPONENTEN GEHÖREN DER PRODUCT PAGE -> HIER NUR GELIEHEN */}
        <MatchingEngineFlow />
        <SelectionPortalPhases />
        <SelectionPortalHighlights />
        
        <div className='w-full h-fit lg:px-64 my-16 px-4'>
          <div className='border rounded-[30px] bg-opacity-90 shadow-lg p-8'>
              <BookADemo />
          </div>
        </div>
    </div>
  )
}

export default ForSeekersPage