import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { parseDate } from '../../../utils/dateParser';

const JobsWidget = ({wrapperStyle, titleStyle, dateStyle}) => {
    const [ jobs, setJobs ] = useState();
    const [ retries, setRetries ] = useState(0);
    const navigate = useNavigate();

    const handleFetchJobs = async () => {
        const res = await fetch(`${process.env.REACT_APP_HCMS_URL}/api/job-ads?sort=updatedAt:desc&populate=*&fields=*`, { 
            method: 'GET', 
            headers: new Headers({
                'Authorization': 'Bearer '+ process.env.REACT_APP_HCMS_KEY, 
                'Content-Type': 'application/x-www-form-urlencoded'
            }),
        });
        
        if (res.ok) {
            try {
                const json = await res.json();
                setJobs(json.data);
                // console.log(json.data);
            } catch(error) {
                console.log(error);
            }
        } else {
            setRetries(prev => prev+1)
        }
    }

    useEffect(() => {
        console.log(retries)
        if (retries == 0) {
            handleFetchJobs()
        } else if (typeof articles === "undefined") {
            const timer = setTimeout(handleFetchJobs, 1000)
            return () => clearTimeout(timer)
        }
    }, [retries])

    if (typeof jobs === "undefined") return <div className='p-4 text-white'>Loading...</div>
    return (
    <div className={``+wrapperStyle}>
        {
            jobs.slice(0,3).map(job => {
                return (
                    <div key={job.id} className={`cursor-pointer group rounded-r hover:bg-white px-4`} onClick={() => navigate("/jobs/job?jobId=" + String(job.id))}>
                        <p className={``+dateStyle}>{parseDate(job.attributes.updatedAt)}</p>
                        <h3 className={``+titleStyle}>{job.attributes.jobTitle}</h3>
                    </div>
                )
            })
        }
    </div>
  )
}

export default JobsWidget