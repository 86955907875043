import { useEffect, useState } from "react";

const useTypewriter = (availbleTexts, typingSpeed) => {
    const [visibleText, setVisibleText] = useState("");
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [typingFinished, setTypingFinished] = useState(false);
    const [typing, setTyping] = useState(true);
    
    useEffect(() => {
        const timer = typewriter();

        return () => {
            clearTimeout(timer);
          };
    }, [currentCharIndex])

    const typewriter = () => {
        // if (typeof ref === "undefined" || ref == null) return;
        
        let timer;
        if (typing) {
            if (currentCharIndex < availbleTexts[currentTextIndex].length) {
                timer = setTimeout(write, typingSpeed);
            } else {
                setTypingFinished(true);
                setTyping(false);
                timer = setTimeout(remove, typingSpeed*20);
            }
        } else {
            if (currentCharIndex >= 0) {
                setTypingFinished(false);
                timer = setTimeout(remove, typingSpeed);
            } else {
                if (currentTextIndex < availbleTexts.length-1) { setCurrentTextIndex(prev => prev+1); }
                else { setCurrentTextIndex(0); }
                setTyping(true);
                timer = setTimeout(write, typingSpeed*10);
            }
        }
        return timer;
    }
    
    const write = () => {
        // ref.current.innerHTML += availbleTexts[currentTextIndex].charAt(currentCharIndex);
        setVisibleText(prev => prev + availbleTexts[currentTextIndex].charAt(currentCharIndex));
        setCurrentCharIndex(prev => prev+1);
    }
    
    const remove = () => {
        // ref.current.innerHTML = ref.current.innerHTML.slice(0,currentCharIndex);
        setVisibleText(prev => prev.slice(0,currentCharIndex));
        setCurrentCharIndex(prev => prev-1);
    }

    return [visibleText, typingFinished];

}

export default useTypewriter