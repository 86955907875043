import React, { useRef, useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./utils/typewriter.css"

import Homepage from './pages/01_Homepage/Homepage'; 
import Cookie from './layout/Cookie';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import ScrollToTop from './layout/ScrollToTop';
import ErrorPage from './pages/00_Other/ErrorPage/ErrorPage';
import Imprint from './pages/00_Other/Imprint/Imprint';
import Blog from './pages/03_Blog/Blog';
import ProductOverview from './pages/02_Product/ProductOverview';
import BlogOverview from './pages/03_Blog/Features/BlogOverview';
import BlogArticle from './pages/03_Blog/Features/BlogArticle';
import AboutUs from './pages/04_AboutUs/AboutUs';
import Jobs from './pages/05_Jobs/Jobs';
import SolutionOverview from './pages/02_Solution/SolutionOverview';
import ContactPage from './pages/06_Contact/Contact';
import MatchingEngine from './pages/02_Product/MatchingEngine';
import SelectionPortal from './pages/02_Product/SelectionPortal';
import JobsOverview from './pages/05_Jobs/Features/JobsOverview';
import JobAd from './pages/05_Jobs/Features/JobAd';
import ForSeekersPage from './pages/02_Solution/Features/ForSeekersPage';
import ForVendorsPage from './pages/02_Solution/Features/ForVendorsPage';
import ForConsultantsPage from './pages/02_Solution/Features/ForConsultantsPage';
import PrivacyPolicy from './pages/00_Other/PrivacyPolicy/PrivacyPolicy';
import AnalyticsInjector from './analytics/AnalyticsInjector';

const App = () => {
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);

  const [cookieAccepted, setCookieAccepted] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [debouncing, setDebouncing] = useState(false);

  // const handleScroll = useCallback(() => {
  //   const currentScrollPos = window.scrollY;

  //   // Avoid rapid toggling of visibility when near the top
  //   if (!debouncing) {
  //     setDebouncing(true);
  //     setTimeout(() => {
  //       setVisible(currentScrollPos < prevScrollPos || currentScrollPos <= 0);
  //       setPrevScrollPos(currentScrollPos);
  //       setDebouncing(false);
  //     }, 100); // Debounce timeout of 100ms
  //   }
  // }, [prevScrollPos, debouncing]);
  // // }, [prevScrollPos, debouncing]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [handleScroll]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AnalyticsInjector>
      <ScrollToTop />
      <div className='flex flex-col min-h-screen'>
        
        <div className={`fixed w-full z-50 transition-all ease-in-out duration-500 ${visible ? 'top-0' : '-top-full'}`}>
          <Navbar sectionRefs={{ section2: section2Ref, section3: section3Ref, section4: section4Ref, section5: section5Ref }}/>
        </div>
        
        { cookieAccepted ? <></> : <Cookie />}
        
        <div className='pt-32 grow'>
          <Routes>
            <Route path='/' element={<Homepage section2Ref={section2Ref} section3Ref={section3Ref} section4Ref={section4Ref} section5Ref={section5Ref} />} />
            <Route path='/solution' element={<SolutionOverview />} />
            <Route path='/solution/for-seekers' element={<ForSeekersPage />} />
            <Route path='/solution/for-vendors' element={<ForVendorsPage />} />
            <Route path='/solution/for-consultants' element={<ForConsultantsPage />} />
            <Route path='/product' element={<ProductOverview />} />
            <Route path='/product/matching-engine' element={<MatchingEngine />} />
            <Route path='/product/selection-portal' element={<SelectionPortal />} />
            <Route path='/blog' element={<Blog />}>
              <Route index={true} element={<BlogOverview />} />
              <Route path='article' element={<BlogArticle />} />
            </Route>
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/jobs' element={<Jobs />}>
              <Route index={true} element={<JobsOverview />} />
              <Route path='job' element={<JobAd />} />
            </Route>
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/imprint' element={<Imprint />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </div>

        <Footer />

      </div>
      </AnalyticsInjector>
    </Router>
  );
};

export default App;