import React, { useState } from 'react'
import { CheckMark, TiltBoxSelectionPortal } from '../../01_Homepage/Features/ProductSection'
import { useNavigate } from 'react-router-dom'
import LargeImageZoom from '../../../components/LargeImageZoom';

const SelectionPortalHighlights = () => {
    const navigate = useNavigate();
    const [imageSrcExpanded, setImageSrcExpanded] = useState(null);
    
    return (
    <div className='my-32'>

        <div className='w-full flex flex-col items-center mb-16 px-4'>
            <h1 className='lg:text-4xl text-2xl text-center font-bold'>Smart functions for maximum efficiency</h1>
            <p className='text-md text-center text-gray-400 w-2/3'>Suggestions, templates, instructions and collaboration enable a smooth process.</p>
        </div>

        <div className='bg-opacity-30'>

            <div className='lg:flex items-center w-full lg:px-32 lg:px-32 px-4 mt-32'>
                
                <div className='lg:w-8/12 max-md:mb-8 flex justify-center'>
                    <div className='xl:w-2/3 hover:scale-[110%] transition-all duration-500 cursor-pointer'>
                        <img src="/img/product_pictures/screens_selectionportal/templates.png" alt="" onClick={(e) => setImageSrcExpanded(e.target.src)} />
                    </div>
                </div>


                <div className='lg:w-4/12'>
                    <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Smart templates and suggestions</h2>
                    <p className='mb-4 text-gray-700'>Smart and context-related content suggestions and templates, e.g. for missing requirements or use case scenarios, accelerate the processes and facilitate the correct and complete execution of the selection steps.</p>

                    {/* <button  onClick={() => navigate("/product/selection-portal")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                        <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                        <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                        <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                        </div>
                    </button> */}

                </div>

            </div>

            <div className='lg:flex items-center w-full lg:px-32 px-4 mt-32'>

            <div className='lg:w-8/12 lg:hidden mb-8 flex justify-center'>
                <div className='xl:w-2/3 hover:scale-[110%] transition-all duration-500 cursor-pointer'>
                <img src="/img/product_pictures/screens_selectionportal/help.png" onClick={(e) => setImageSrcExpanded(e.target.src)} alt="" />
                </div>
            </div>

            <div className='lg:w-4/12'>
                <h2 className='lg:text-4xl text-2xl font-bold text-primary mb-8'>Instructions and guidance through the process</h2>
                <p className='mb-4 text-gray-700'>Targeted and comprehensible instructions guide different user groups intuitively through the selection process to consider all stakeholders' individual needs.</p>

                {/* <button  onClick={() => navigate("/product/matching-engine")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                    <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                    <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                    <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                    </div>
                </button> */}

            </div>

            <div className='lg:w-8/12 max-md:hidden flex justify-center'>
                <div className='xl:w-2/3 hover:scale-[110%] transition-all duration-500 cursor-pointer'>
                <img src="/img/product_pictures/screens_selectionportal/help.png" onClick={(e) => setImageSrcExpanded(e.target.src)} alt="" />
                </div>
            </div>

            </div>

            <div className='lg:flex items-center w-full lg:px-32 px-4 mt-32'>
                
                <div className='lg:w-8/12 max-md:mb-8 flex justify-center'>
                    <div className='xl:w-2/3 hover:scale-[110%] transition-all duration-500 cursor-pointer'>
                    <img src="/img/product_pictures/screens_selectionportal/communication.png" onClick={(e) => setImageSrcExpanded(e.target.src)} alt="" />
                    </div>
                </div>


                <div className='lg:w-4/12'>
                    <h2 className='lg:text-4xl text-2xl font-bold mb-8'>Simple, centralized collaboration and communication</h2>
                    <p className='mb-4 text-gray-700'>Centralizing the communication between project team members, selection consultants and software vendors enables efficient and context-specific interactions.</p>

                    {/* <button  onClick={() => navigate("/product/selection-portal")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
                        <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
                        <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
                        <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
                        </div>
                    </button> */}

                </div>

            </div>

            </div>

            {
                imageSrcExpanded != null ? (
                <LargeImageZoom imageSrc={imageSrcExpanded} handleClose={() => setImageSrcExpanded(null)} imageStyling={"none"} />
                ) : (<></>)
            }
    </div>
  )
}

export default SelectionPortalHighlights