import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TiltBox from '../../../components/TiltBox'

export const CheckMark = () => {
  return (
    <svg class="flex-shrink-0 w-3.5 h-3.5 text-primary2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
    </svg>
  )
}

const TiltBoxMatchingEngine = () => {
  return (
    <TiltBox>
      <img src="/img/product_pictures/tilt_matchingengine/matchingengine_page.png" alt="" style={{transform: "translateZ(0px)"}} className="absolute w-[70%]" />
      <img src="/img/product_pictures/tilt_matchingengine/matchingengine_searchbar.png" alt="" style={{transform: "translateZ(50px)"}} className="absolute w-[70%] top-[55%] left-[5%]" />
      <img src="/img/product_pictures/tilt_matchingengine/matchingengine_results.png" alt="" style={{transform: "translateZ(40px)"}} className="absolute w-[40%] top-[45%] left-[55%]" />
      <img src="/img/product_pictures/tilt_matchingengine/matchingengine_circles.png" alt="" style={{transform: "translateZ(45px)"}} className="absolute w-[10%] top-[65%] left-[85%]" />
    </TiltBox>
  )
}

export const TiltBoxSelectionPortal = () => {
  return (
    <TiltBox>
      <img src="/img/product_pictures/tilt_selectionportal/selectionportal_page.png" alt="" style={{transform: "translateZ(0px)"}} className="absolute w-[80%]" />
      <img src="/img/product_pictures/tilt_selectionportal/selectionportal_req.png" alt="" style={{transform: "translateZ(50px)"}} className="absolute w-[65%] top-[35%] left-[40%]" />
      <img src="/img/product_pictures/tilt_selectionportal/selectionportal_chat.png" alt="" style={{transform: "translateZ(40px)"}} className="absolute w-[35%] top-[55%] left-[65%]" />
      <img src="/img/product_pictures/tilt_selectionportal/selectionportal_score.png" alt="" style={{transform: "translateZ(45px)"}} className="absolute w-[35%] top-[60%] left-[0%]" />
    </TiltBox>
  )
}

const ProductSection = () => {
  const navigate = useNavigate();
  const [consentGiven, setConsentGiven] = useState(false);

  const handleGiveConsent = () => {
    setConsentGiven(true);
  }

  return (
    <>
    <section className="my-32 flex flex-col items-center">
      <div className='lg:max-w-[800px] px-4'>
        <h2 id="vision" className="lg:text-4xl text-2xl text-center text-primary font-semibold">Navigating the enterprise systems market</h2>
        <p className="mt-8 text-gray-700">
          In today's digital age, businesses, including SMEs, rely heavily on their software infrastructure. However, the process of choosing the right software from the vast and fragmented software market is challenging and overwhelming.
        </p>
        <p className="mt-2 text-gray-700">
          <span className="swap_text">swap</span> is an AI-driven web-based platform designed to boost the digital transformation journey, streamlining the software selection process and bridging the gap between businesses and software providers for efficient, transparent, and cost-effective decision-making.
        </p>

      </div>
        
      {/* <img src="/img/vision.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 mt-8 w-full mx-5 rounded-lg mx-auto" /> */}
      
      <div className='bg-gradient-to-br from-white to-gray-200 bg-opacity-30 mt-32 py-24'>

        <div className='lg:flex items-center w-full lg:space-x-16 lg:px-16 px-4'>
          
          <div className='w-full lg:hidden mb-16'>
            <img src="/img/product_pictures/screens_matchingengine/matchingengine.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
          </div>

          <div className='lg:w-4/12 w-full'>
            <p className='text-gray-800 text-sm uppercase font-semibold'>SWAP Matching Engine</p>
            <h2 className='lg:text-4xl text-2xl font-bold text-primary mb-8'>Find your enterprise system in just 6 minutes</h2>
            <p className='mb-4 text-gray-700'>SWAP analyzes your AI-based company to suggest suitable enterprise systems that you need and that suit you. Just enter the URL of your company and off you go.</p>
            
            <ul class="space-y-4 text-left font-semibold text-gray-700">
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <CheckMark />
                  <span>AI-based requirements analyses</span>
              </li>
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <CheckMark />
                  <span>Comprehensive system databases</span>
              </li>
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <CheckMark />
                  <span>Detailed system function catalogs</span>
              </li>
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <CheckMark />
                  <span>Automated system matching and ranking</span>
              </li>
          </ul>

          <button  onClick={() => navigate("/product/matching-engine")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
              <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
              <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
            </div>
          </button>

          </div>
          
          {/* <div className='lg:w-8/12 w-full max-md:hidden'>
            <img src="/img/matching_engine.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
          </div> */}

          <div className='lg:w-8/12 max-md:hidden flex justify-center'>
            <div className='xl:w-[700px] xl:h-[700px] w-[500px] h-[500px]'>
              <TiltBoxMatchingEngine />
            </div>
          </div>

        </div>
        
        
        <div className='lg:flex items-center w-full lg:space-x-16 mt-16 px-16 px-4'>

          <div className='lg:hidden relative max-md:mb-16'>
            <img src="/img/product_pictures/screens_selectionportal/selectionportal.png" alt="logo" className="cursor-pointer transition-all duration-500 hover:scale-105 " />
          </div>
          
          <div className='lg:w-6/12 max-md:hidden flex justify-center'>
            <div className='xl:w-[600px] xl:h-[600px] w-[450px] h-[450px]'>
              <TiltBoxSelectionPortal />
            </div>
          </div>


          <div className='lg:w-4/12 w-full'>
            <p className='text-gray-800 text-sm uppercase font-semibold'>SWAP Selection Portal</p>
            <h2 className='lg:text-4xl text-2xl font-bold text-primary mb-8'>Maximize the reliability of your enterprise system selection efficiently </h2>
            <p className='mb-4 text-gray-700'>SWAP offers a comprehensive platform on which AI-based analyses can be extended and all parties involved in software selection can work together efficiently.</p>
            
            <ul class="space-y-4 text-left font-semibold text-gray-700">
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <CheckMark />
                  <span>Useful tools for requirement surveys, presentation assessments and much more</span>
              </li>
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <CheckMark />
                  <span>Effective collaboration and communication functions </span>
              </li>
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <CheckMark />
                  <span>Methodical assistance and instructions</span>
              </li>
              <li class="flex items-center space-x-3 rtl:space-x-reverse">
                  <CheckMark />
                  <span>Samples, templates and automated recommendations</span>
              </li>
          </ul>

          <button  onClick={() => navigate("/product/selection-portal")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
            <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
              <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
              <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
            </div>
          </button>

          </div>

        </div>

      </div>
      
    </section>

    <section className="mb-40 mx-auto px-4 lg:max-w-[800px]">
    <h2 id="how-it-works" className="lg:text-4xl text-2xl text-primary font-semibold">How it works</h2>

    <h3 className="mt-8 mb-2 text-gray-700 text-lg font-semibold">Watch the video</h3>
    <p className="my-2 text-gray-700">
      The video explains the concept of the <span className="swap_text">swap</span> Matching-Engine using the example of ERP selection.
    </p>

    <div className="rounded-lg overflow-hidden h-[250px] md:h-[350px] xl:h-[450px] bg-primary">
      <div role="status" id="video_placeholder" style={{display: consentGiven ? "none" : ""}} className="overflow-y-auto cursor-pointer h-[250px] md:h-[350px] xl:h-[450px] flex items-center justify-center bg-gray-300 rounded-lg" onClick={() => handleGiveConsent()}>
        <div className="flex flex-col items-center">
          
          <svg className="w-10 h-10 max-md:w-5 max-md:h-5 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
          </svg>
        <p className="max-sm:text-sm text-center text-gray-700 p-6">
          Before we display the video, we need your consent. Loading the video will transmit information to YouTube, including your IP address, and cookies will be stored on your device. Please click here to give your consent and view the video. More details can be found in our privacy policy.
        </p>
        </div>
          <span className="sr-only">Loading...</span>
      </div>
      
      <iframe id="youtube_embedd" className='h-[250px] md:h-[350px] xl:h-[450px] w-full' width="560" height="315" src={consentGiven ? "https://www.youtube-nocookie.com/embed/_nDkQjR4PyU?si=2LKf8dPHcFd7dQdJ&amp;rel=0" : "about:blank"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      
    </div>

    <button  onClick={() => navigate("/product")} className="mt-8 h-16 text-base font-medium text-white items-center group transition-all focus:ring-4 focus:outline-none rounded-lg text-center" type="button" >
      <div className="flex w-full text-lg font-semibold text-primary group-hover:text-primary3 transition-all">
        <p className='underline underline-offset-4 group-hover:underline-offset-8 transition-all'>Learn more</p>
        <p className='translate-x-2 group-hover:translate-x-4 transition-all'>→</p>
      </div>
    </button>

    </section>
    </>
  )
}

export default ProductSection