import React from 'react'

const LargeImageZoom = ({imageSrc, handleClose, imageStyling}) => {
  return (
    <div className={`fixed w-[100vw] h-[100vh] z-[100] left-0 flex items-center justify-center bg-gray-300 bg-opacity-50 cursor-pointer top-0`} onClick={handleClose} >
        <img class={`h-[70%] w-auto object-contain rounded-lg ${imageStyling || 'shadow-lg border'}`} src={imageSrc} alt="" />
    </div>
  )
}

export default LargeImageZoom