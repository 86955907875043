import React, {forwardRef, useState } from 'react';
import Gallery from '../../../components/Gallery';

const contents = [
    {
        texts: [
            { heading: "Dashboards and analyses", text: "Always keep an eye on the progress of your software selection with clear dashboards and smart analyses." },
            { heading: "Shortlists and system comparison", text: "Continuously compare the selection systems, evaluate their performance in the various selection steps and make changes to the shortlist." }
        ],
        images: ["/img/product_pictures/screens_selectionportal/cockpit_1.png", "/img/product_pictures/screens_selectionportal/ranking_1.png"]
    },
    {
        texts: [
            { heading: "Collect requirements centrally", text: "Explore insights with the sectorlens engine, leveraging AI for comprehensive data analysis. Our platform seamlessly integrates various data sets, enabling swift, data-driven decisions." },
            { heading: "Prioritize and higlight", text: "Work with the requirements using intelligent categorization and search functions. Assign them importance and highlight them to stimulate discussion." },
        ],
        images: ["/img/product_pictures/screens_selectionportal/reqs_1.png", "/img/product_pictures/screens_selectionportal/reqs_2.png"],
    },
    {
        texts: [
            { heading: "Create usecase scenarios for system presentations", text: "Create scenarios and subtasks as a basis for a chronological demonstration in structured vendor presentations. Ensure the comparability of the systems and individualization of the presentations offered to you." },
        ],
        images: ["/img/product_pictures/screens_selectionportal/scenarios_1.png", "/img/product_pictures/screens_selectionportal/scenarios_2.png"],
    },
    {
        texts: [
            { heading: "Find suitable dates", text: "Automatically find dates that suit all stakeholders, such as key users, consultants and system vendors for the presentations. Save time on communication and coordination of the many participants." },
            { heading: "Evaluate and interact live", text: "Evaluate the fulfillment of the usecase scenarios in the system presentations and exchange ideas with your colleagues in the process. If you have any questions for the vendor, you can also ask them live in the chat." },
        ],
        images: ["/img/product_pictures/screens_selectionportal/scheduling_calendar.jpg"],
    },
    {
        texts: [
            { heading: "Central storage and processing", text: "Store all quotation and contract documents for the systems in one place so that everyone involved in the project has access and can work with the same documents." },
            { heading: "Collaborate and annotate", text: "Work together on the offer and contract documents to exchange details and move negotiations forward." },
            { heading: "Automatic scans and smart checklists", text: "AI automatically scans contracts and notifies you of any anomalies, e.g. if important clauses are missing or conditions should be discussed. Smart checklists enable you to analyze contracts and conduct negotiations." }
        ],
        images: ["/img/product_pictures/screens_selectionportal/req_details.jpg"],
    }
];

const SelectionPortalPhases = () => {
    const [currentContentIndex, setCurrentContentIndex] = useState(0);

    const updateContent = (index) => {
      setCurrentContentIndex(index);
    };

    return (
    <div className='mt-16 flex flex-col my-16 py-16 items-center bg-gradient-to-br from-white to-gray-200'>

        <div className='w-full flex flex-col items-center mb-16 px-16'>
            <h1 className='text-4xl text-center font-bold'>Tools to support each phase of the selection process</h1>
            <p className='text-md text-center text-gray-400 w-2/3'>Comprehensive guidance and useful tools for an efficient software selection process.</p>
        </div>

        <div className="w-fit flex bg-primary2 border-gray-300 bg-opacity-10 lg:m-3 border-2 rounded-full md:items-center max-md:items-center max-md:flex-col max-md:rounded-lg max-md:grid max-md:grid-cols-2 max-md:justify-items-center max-md:mx-8">
            {['Project Management', 'Requirement Engineering', 'Usecase Scenario Development', 'Vendor and System Presentations', 'Contract Negotiations'].map((label, index) => (
                <div className="relative p-3 max-md:w-full flex item-center" key={index}>
                    { index != 0 ? <div className={`absolute top-1 left-1 text-sm font-semibold border rounded-full z-[1] w-8 h-8 flex items-center justify-center ${currentContentIndex === index ? 'bg-primary text-white' : 'bg-gray-300 text-primary'}`}>{index}</div> : <></>}
                    <button className={`relative px-10 py-2 max-md:h-24 h-16 max-md:w-full text-sm font-semibold rounded-full max-md:rounded-lg border-2 border-gray-300 hover:text-white hover:border-primary hover:bg-primary ${currentContentIndex === index ? 'bg-primary text-white' : ''}`} onClick={() => updateContent(index)}>
                        {label}
                    </button>
                </div>
            ))}
        </div>

        <div className="px-32 lg:grid lg:grid-cols-2 flex mb-6 items-start justify-center text-gray-700 relative w-full lg:flex-row max-md:flex-col max-md:px-4 lg:mt-12 mt-4">
            <div className="basis-2/5 text-lg rounded-[15px]">
                {contents[currentContentIndex].texts.map((content, index) => (
                    <div className="mt-2 flex p-4 justify-between rounded-lg items-center" key={index}>
                        <div>
                            <h6 className="text-xl font-semibold mb-2">{content.heading}</h6>
                            <p className="pb-2">{content.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mx-4 rounded-lg w-auto justify-center items-center h-[500px]">
                <Gallery imageSrcs={contents[currentContentIndex].images} />
            </div>
        </div>

    </div>
  )
}

export default SelectionPortalPhases