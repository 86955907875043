import React, { useCallback, useEffect, useRef, useState } from 'react';
import BookADemo from '../../components/BookADemo';
import MatchingEngineFlow from './Features/MatchingEngineFlow';
import MatchingEngineFeatures from './Features/MatchingEngineFeatures';

const MatchingEngine = () => {
    
    return (
    <div className="mb-2 text-gray-700 flex flex-col items-center justify-center relative w-full justify-center max-md:h-fit">

        <div className='w-full flex flex-col items-center px-4'>
            <h1 className='text-4xl text-center font-bold'>Find your enterprise system in just 6 minutes</h1>
            <p className='text-md text-center text-gray-400 w-2/3'>Experience how our advanced AI connects your business with the ideal enterprise system solutions, precisely suited to meet your specific needs and goals. By leveraging cutting-edge technology, we ensure a seamless and efficient process to identify the best matches for your unique requirements.</p>
        </div>

        <MatchingEngineFlow />

        <MatchingEngineFeatures />

        <div className='w-full h-fit lg:px-64 my-32 px-4'>
            <div className='border rounded-[30px] bg-opacity-90 shadow-lg p-8'>
                <BookADemo />
            </div>
        </div>

    </div>
  )
}

export default MatchingEngine