import React, { useEffect, useState } from 'react'
import LargeImageZoom from './LargeImageZoom';

const Gallery = ({imageSrcs}) => {
    const [imageExpandedIdx, setImageExpandedIdx] = useState(null);
    const [selectedImageIdx, setSelectedImageIdx] = useState(0);

    useEffect(() => {
        setSelectedImageIdx(0);
    }, [imageSrcs])

    const selectImage = (idx) => {
        setSelectedImageIdx(idx)
    }

  return (
    <div className='h-full'>

        <div className={`h-[80%] h-fit w-fit`}>
            <img class={`rounded-lg transition-all duration-500 cursor-pointer border shadow-lg object-contain max-h-full`} src={imageSrcs[selectedImageIdx]} alt="" onClick={() => setImageExpandedIdx(selectedImageIdx)} />
        </div>
        <div class="h-[20%] flex gap-4 overflow-x-auto py-4">
            {
                imageSrcs.length > 1 ? imageSrcs.map((imageSrc, idx) => (
                    <>
                        <img key={imageSrc} class={`object-contain rounded-lg cursor-pointer transition-all duration-500 border-2 shadow-lg ${selectedImageIdx == idx ? "border-primary" : ""}`} src={imageSrc} alt="" onClick={() => selectImage(idx)} />
                    </>
                )) : <></>
            }
        </div>

        {
            imageExpandedIdx != null ? (
            <LargeImageZoom imageSrc={imageSrcs[imageExpandedIdx]} handleClose={() => setImageExpandedIdx(null)} />
            ) : (<></>)
        }
        
    </div>
  )
}

export default Gallery